<template>
	<div>
		<h4>{{ $t("Registrierung") }}</h4>
		<div class="row">
			<div class="col-lg-12">
				<div class="alert alert-danger" v-if="error">
					{{ $t(error) }}
				</div>
				<div class="alert alert-success" v-if="success">
					{{ $t(success) }}
				</div>
				<form @submit.prevent="register" autocomplete="off">
					<div class="panel p-2 mb-4">
						<div class="row">
							<div class="col-lg-6">
								<h5>{{ $t("Vorname") }}:</h5>
								<b-form-input v-model="firstName" name="firstName" autocomplete="current-firstName" required value></b-form-input>
							</div>
							<div class="col-lg-6">
								<h5>{{ $t("Nachname") }}:</h5>
								<b-form-input v-model="lastName" name="lastName" autocomplete="current-lastName" required value></b-form-input>
							</div>
						</div>
						<h5>Email:</h5>
						<b-form-input v-model="email" type="email" name="email" autocomplete="current-email" required value></b-form-input>
						<div class="row">
							<div class="col-lg-4">
								<h5>{{ $t("PLZ") }}:</h5>
								<b-form-input v-model="postCode" name="postCode" autocomplete="current-postCode" required value></b-form-input>
							</div>
							<div class="col-lg-8">
								<h5>{{ $t("Stadt") }}:</h5>
								<b-form-input v-model="city" name="city" autocomplete="current-city" required value></b-form-input>
							</div>
						</div>
						<h5>{{ $t("Strasse") }}:</h5>
						<b-form-input v-model="street" name="street" autocomplete="current-street" required value></b-form-input>
						<h5>{{ $t("Telefon") }}:</h5>
						<b-form-input v-model="phone" name="phone" autocomplete="current-phone" required value></b-form-input>
						<h5>{{ $t("Adress Zusatz") }}:</h5>
						<b-form-input v-model="address" name="address" autocomplete="current-address" value></b-form-input>

						<div class="row d-flex align-items-end">
							<div class="col-lg-6">
								<h5>{{ $t("Passwort") }}:</h5>
								<b-form-input v-model="password" type="password" name="password" autocomplete="current-password" required value></b-form-input>
							</div>
							<div class="col-lg-6">
								<h5>{{ $t("Passwort Wiederholen") }}:</h5>
								<b-form-input v-model="password2" type="password" name="password2" autocomplete="current-password2" required value></b-form-input>
							</div>
						</div>

						<button class="btn btn-primary mx-0 my-3" type="submit" name="button">
							{{ $t("Register") }}
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import DataService from "@/services/DataService";
	export default {
		name: "Register",
		data() {
			return {
				firstName: "",
				lastName: "",
				email: "",
				postCode: "",
				city: "",
				street: "",
				phone: "",
				address: "",
				password: "",
				password2: "",
				error: null,
				success: null,
			};
		},
		methods: {
			register() {
				if (this.password == this.password2) {
					this.$store
						.dispatch("register", {
							loginName: this.email,
							password: this.password,
						})
						.then(() => {
							DataService.userAddress({
								lastName: this.lastName,
								firstName: this.firstName,
								street: this.street,
								address: this.address,
								postCode: this.postCode,
								phone: this.phone,
								city: this.city,
							})
								.then(() => {
									this.success = "Registration Successful!";
									this.error = false;
									this.$store.dispatch("login", {
										LoginName: this.email,
										Password: this.password,
									});
									if (this.total == 0) {
										setTimeout(() => {
											this.$router.push({ name: "order" });
										}, 500);
									}
								})
								.catch((err) => {
									this.error = err.response.data;
								});
						})
						.catch((err) => {
							this.error = err.response.data;
						});
				} else {
					this.error = "Passwörter stimmen nicht überein?";
				}
			},
		},
	};
</script>

<style scoped>
	h5 {
		margin-top: 5px;
	}
</style>
