<template>
	<div class="row">
		<div class="col-lg-12">
			<div v-if="items.length" class="cart panel p-2">
				<!-- **************** MESSAGE OF TODAY ******************* -->
				<b-modal ref="busy-message" id="busy-message" hide-footer hide-header>
					<div v-html="messageOfToday"></div>
					<button class="btn btn-md btn-secondary mx-0 mt-3 btn-block" @click="$bvModal.hide('busy-message')">
						{{ $t("Okay") }}
					</button>
				</b-modal>
				<!-- ***************************************************** -->
				<div class="row">
					<!-- **************** LEFT PANEL ******************* -->
					<div class="col-xl-6 my-2">
						<div v-show="postLogin" v-if="deliveryPossible" class="panel t1 p-2 mb-3">
							<!-- **************** LIFERADRESSE ******************* -->
							<h4>{{ $t("Lieferadresse") }}:</h4>
							<table class="table table-striped table-borderless table-sm mb-0" :class="'table-' + theme()">
								<thead class="thead-dark">
									<tr>
										<th scope="col">{{ $t("Name") }}</th>
										<th scope="col">{{ $t("Adresse") }}</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in user.addresses" :key="item.id" class="pointer" @click="makeDefaultAddress(item.id)">
										<td>{{ item.firstName }} {{ item.lastName }}</td>
										<td>
											{{ item.street }}
											<br />
											{{ item.postCode }} {{ item.city }}
										</td>
										<td class="makeDefault">
											<input type="radio" v-model="makeDefault" name="makeDefault" :value="item.id" />
											<label for="makeDefault"></label>
										</td>
									</tr>
									<tr class="pointer" v-if="PickupPossible" @click="makeDefaultAddress(-1)">
										<td colspan="2" height="56px" align="center">
											{{ $t("Abholung") }}
										</td>
										<td class="makeDefault">
											<input type="radio" v-model="makeDefault" name="makeDefault" :value="-1" />
											<label for="makeDefault"></label>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="text-right link color" @click="newAddress()">
								{{ $t("Neue Adresse") }}
							</div>
						</div>
						<!-- **************** ABHOLUNG ******************* -->
						<div v-show="postLogin" v-else class="panel t1 p-2 mb-3">
							<h4>{{ $t("Abholung") }}:</h4>
							<table class="table table-striped table-borderless table-sm mb-0" :class="'table-' + theme()">
								<tbody>
									<tr class="pointer" v-if="PickupPossible" @click="makeDefaultAddress(-1)">
										<td colspan="2" height="56px" align="center">
											{{ $t("Abholung") }}
										</td>
										<td class="makeDefault">
											<input type="radio" v-model="makeDefault" name="makeDefault" :value="-1" />
											<label for="makeDefault"></label>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<!-- **************** LIEFERZEIT ******************* -->
						<div v-show="postLogin" class="panel t1 p-2 mb-3">
							<h4>{{ $t("Lieferzeit") }}:</h4>
							<p>
								<b
									><i>{{ $t(getWeekday()) }}, {{ getTime() }}</i></b
								>
								-
								<span v-if="onDuty">
									{{ $t("Geöffnet") }}
									<span v-for="(item, index) in openHours()" :key="index"> {{ item.from }} {{ $t("bis") }} {{ item.to }} </span>
								</span>
								<span v-else>
									{{ $t("Wir haben derzeit geschlossen") }}
								</span>
							</p>
							<b-form-select v-model="deliveryMinutes" class="mb-3" style="font-size: 20px" required>
								<template #first>
									<b-form-select-option :value="0">{{ $t(DeliveryTimeDef) }}</b-form-select-option>
								</template>
								<b-form-select-option v-for="(item, index) in DeliveryOptions" :key="index" :value="item['time']">
									{{ $t(item["text"]) }}
								</b-form-select-option>
							</b-form-select>
						</div>

						<!-- **************** ANMERKUNG ******************* -->
						<div v-show="postLogin" class="panel t1 p-2">
							<h4>{{ $t("Anmerkung") }}:</h4>
							<b-form-textarea v-model="comment" class="mb-3" style="font-size: 20px" />
						</div>
					</div>
					<!-- **************** RIGHT PANEL ******************* -->
					<div class="col-xl-6 my-2">
						<div v-show="activationMissing" class="alert alert-danger">
							{{ $t("Aktivierung erforderlich!") }}
						</div>
						<div v-show="preLogin" class="panel t1 p-2 mb-3">
							<div v-show="loginPanel">
								<loginComp />
								<h5 class="color text-right pointer" @click="register()">
									{{ $t("Noch kein Konto? Hier Registrieren") }}
								</h5>
							</div>
							<div v-show="registerPanel">
								<registerComp />
								<h5 class="color text-right pointer" @click="login()">
									{{ $t("Schon registriert? Hier Anmelden") }}
								</h5>
							</div>
						</div>
						<div v-show="postLogin">
							<div v-show="paymentSelect" class="panel t1 p-2">
								<!-- **************** PAYMENTBUTTONS ******************* -->
								<h4>{{ $t("Zahlweise") }}:</h4>
								<div class="text-center mx-auto">
									<div class="alert alert-success" v-if="nonce">
										{{ $t("Zahlung Erfolgreich") }}
									</div>
								</div>
								<div class="alert alert-danger" v-if="error" v-html="error"></div>
								<div v-show="allowCheckout">
									<div role="tablist">
										<b-card no-body v-if="AllowPayPal" class="mb-1">
											<!-- **************** PAYPAL ******************* -->
											<b-card-header header-tag="header" class="p-1" role="tab">
												<b-button block v-b-toggle.accordion-2 variant="primary" class="btn-lg mx-0">{{ $t("PayPal") }}</b-button>
											</b-card-header>
											<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
												<b-card-body>
													<b-card-text class="text-center">
														<h5>
															{{ amount | currency(cSymb) }}
															<span v-if="makeDefault != -1">
																({{ $t("inkl") }}
																{{ DeliveryCost | currency(cSymb) }}
																{{ $t("Lieferkosten") }})
															</span>
															<span v-else>({{ $t("Abholung") }})</span>
														</h5>
														<form>
															<div class="text-center">
																<div id="paypalButton" class="my-3"></div>
															</div>
														</form>
														<div ref="paypal"></div>
													</b-card-text>
												</b-card-body>
											</b-collapse>
										</b-card>
										<!-- **************** CASH ******************* -->
										<b-card no-body class="mb-1" v-if="AllowCash">
											<b-card-header header-tag="header" class="p-1" role="tab">
												<b-button block v-b-toggle.accordion-3 variant="primary" class="btn-lg mx-0">
													{{ $t("Barzahlung") }}
												</b-button>
											</b-card-header>
											<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
												<b-card-body>
													<b-card-text class="text-center">
														<h5>
															{{ amount | currency(cSymb) }}
															<span v-if="makeDefault != -1">
																({{ $t("inkl") }}
																{{ DeliveryCost | currency(cSymb) }}
																{{ $t("Lieferkosten") }})
															</span>
															<span v-else>({{ $t("Abholung") }})</span>
														</h5>
														<div v-show="activationMissingCash" class="alert alert-danger">
															{{ $t("Aktivierung erforderlich!") }}
														</div>
														<button v-show="postLoginCash" class="btn btn-lg btn-secondary mx-0 btn-block" @click.prevent="payCash()">
															{{ $t("Kostenpflichtig Bestellen") }}
														</button>
													</b-card-text>
												</b-card-body>
											</b-collapse>
										</b-card>
									</div>
								</div>
							</div>
						</div>
						<!-- **************** PAYMENTINFO ******************* -->
						<div v-show="paymentSubmit" class="panel t1 p-2">
							<div class="panel t1 p-2 text-center">
								<h5>{{ $t(payType) }}</h5>
								<div v-if="paymentMethod == 1 || paymentMethod == 3" class="text-center">
									<h5>
										{{ $t("Betrag") }}:
										{{ (total + DeliveryCost) | currency(cSymb) }}
									</h5>
									<img v-show="spinner" class="mb-0 rotating" src="/assets/img/kunden_logo2.png" alt="Xenia" width="40" />
									<h3>{{ $t("Zahlung in Bearbeitung!") }}</h3>
								</div>
								<div v-else class="text-center">
									<h5>{{ $t("Betrag") }}: {{ total | currency(cSymb) }}</h5>
									<img v-show="spinner" class="mb-0 rotating" src="/assets/img/kunden_logo2.png" alt="Xenia" width="40" />
									<h3>{{ $t("Ihre Bestellung wird verarbeitet!") }}</h3>
								</div>
							</div>
						</div>
						<div v-show="cancellation">
							<b-row class="mt-2">
								<div class="col-lg-12 text-right">
									<button class="btn btn-lg btn-secondary mx-0" @click="goHome()">
										{{ $t("Abbrechen") }}
									</button>
								</div>
							</b-row>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import DataService from "@/services/DataService";
	import loginComp from "@/components/login_comp.vue";
	import registerComp from "@/components/register_comp.vue";

	import { mapGetters, mapActions } from "vuex";
	import Vue from "vue";

	export default {
		name: "CheckOut",
		components: {
			loginComp,
			registerComp,
		},
		data() {
			return {
				user: [],
				makeDefault: -1,
				address: {},
				initialAddress: {},
				paymentMethod: 0,
				paymentSelect: true,
				paymentSubmit: false,
				spinner: false,
				cancellation: true,
				allowCheckout: Boolean,
				deliveryMinutes: 0,
				DeliveryCost: 0,
				DeliveryTime: [],
				DeliveryTimeDef: 0,
				comment: "",
				payType: "",
				input: "",
				nonce: "",
				error: "",
				amount: 0,
				loaded: false,
				paidFor: false,
				posts: [],
				errors: [],
				date: new Date(),
				loginPanel: true,
				registerPanel: false,
				preLogin: true,
				postLogin: false,
				postLoginCash: true,
				activationMissingCash: false,
				activationMissing: false,
				activated: false,
			};
		},
		created() {
			if (this.loggedIn) {
				DataService.getUser(this.companyData.apiKey, this.userData.token).then((response) => {
					this.user = response.data;
					this.initialAddress = response.data.addresses.find((item) => item.id > 0);
					this.address = this.initialAddress;
					if (response.data.addresses.length && this.timeToClosing() == true) {
						this.allowCheckout = true;
						if (this.deliveryPossible) {
							this.makeDefault = this.initialAddress.id;
						} else {
							this.makeDefault = -1;
							this.amount = this.total.toFixed(2);
						}
						this.getDeliveryCost();
					} else {
						this.allowCheckout = false;
						if (!response.data.addresses.length) {
							this.error += Vue.i18n.translate("minAddress") + "<br />";
						}
						if (this.timeToClosing() == false) {
							this.error += Vue.i18n.translate("minOrderTime") + "<br />";
						}
						this.amount = this.total.toFixed(2);
					}
				});
				this.checkActivationStatus();
			}
			var self = this;
			setInterval(function() {
				self.date = new Date();
			}, 1000);
		},
		computed: {
			...mapGetters({ items: "cartProducts" }),
			...mapGetters(["itemsQuantity", "subtotal", "taxes", "total", "userData", "companyData", "loggedIn"]),
			getO() {
				return this.now;
			},
			cSymb() {
				if (this.companyData.storeCurrency === "EUR") {
					return "€";
				} else if (this.companyData.storeCurrency === "USD") {
					return "$";
				} else {
					return this.companyData.storeCurrency;
				}
			},
			loginState() {
				return this.loggedIn;
			},
			messageOfToday() {
				return this.companyData.messageOfToday;
			},
			onDuty() {
				if (this.companyData.onDuty == "closed") {
					return false;
				} else {
					return true;
				}
			},
			PickupPossible() {
				return this.companyData.pickUpPossible;
			},
			deliveryPossible() {
				return this.companyData.deliveryPossible;
			},
			AllowPayPal() {
				return this.companyData.payPalPayment;
			},
			AllowCreditCard() {
				return this.companyData.creditCardViaPaypal;
			},
			allowCheckout24() {
				return this.companyData.allowCheckout24;
			},
			AllowCash() {
				return this.companyData.pickUpPayment;
			},
			activationRequired() {
				return this.companyData.activationRequired;
			},
			DeliveryOptions() {
				var deliverAarray = [];
				for (var i = 1; i < this.DeliveryTime.length; i++) {
					deliverAarray.push({
						text: this.DeliveryTime[i].text,
						time: this.DeliveryTime[i].time,
					});
				}
				return deliverAarray;
			},
			shopCart() {
				var scart = [];
				scart.push({
					payMethod: 0,
					transactionReference: "",
					RoomNumber: "",
					addressId: this.initialAddress.id,
					comment: "",
					total: 0,
					tableNumber: "",
					posList: this.items.map((item) => {
						item = {
							articleId: item.id,
							quantity: item.quantity,
							singlePrice: item.price,
							variantList: item.selectedOptions.map((opt) => opt.options.map((x) => x.id)).join(),
							parent: true,
							name: item.name,
							note: item.note,
						};
						return item;
					}),
				});
				for (var i = 0; i < this.items.length; i++) {
					for (var s = 0; s < this.items[i].selectedOptions.length; s++) {
						for (var m = 0; m < this.items[i].selectedOptions[s].options.length; m++) {
							scart[0].posList.push({
								articleId: this.items[i].selectedOptions[s].options[m].id,
								quantity: this.items[i].selectedOptions[s].options[m].optionQuantity,
								singlePrice: this.items[i].selectedOptions[s].options[m].addPrice,
								variantList: this.items[i].id.toString(),
								parent: false,
								name: this.items[i].selectedOptions[s].options[m].name,
							});
						}
					}
				}
				scart = scart.map(function(e) {
					return JSON.stringify(e);
				});
				scart = scart.join(",");
				return scart;
			},
		},
		watch: {
			loginState(state) {
				if (state == true) {
					this.checkActivationStatus();
					setTimeout(() => {
						this.updateAddress();
					}, 500);
				} else {
					this.postLogin = false;
					this.preLogin = true;
				}
			},
		},

		methods: {
			...mapActions({
				removeAllFromCart: "removeAllFromCart",
			}),
			showModal() {
				this.$refs["busy-message"].show();
			},
			register() {
				this.registerPanel = true;
				this.loginPanel = false;
			},
			login() {
				this.registerPanel = false;
				this.loginPanel = true;
			},
			checkActivationStatus() {
				DataService.getUser(this.companyData.apiKey, this.userData.token).then((response) => {
					if (response.data.isActivated == false) {
						setTimeout(this.checkActivationStatus(), 1000);
						if (this.activationRequired == 0) {
							this.postLogin = false;
							this.preLogin = false;
							this.activationMissing = true;
						} else if (this.activationRequired == 1) {
							this.postLogin = true;
							this.activationMissingCash = true;
							this.postLoginCash = false;
							this.preLogin = false;
						} else if (this.activationRequired == 2) {
							this.postLogin = true;
							this.preLogin = false;
						}
					} else {
						this.activated = response.data.isActivated;
						this.postLogin = true;
						this.activationMissingCash = false;
						this.postLoginCash = true;
						this.preLogin = false;
					}
				});
			},
			updateAddress() {
				DataService.getUser(this.companyData.apiKey, this.userData.token).then((response) => {
					this.user = response.data;
					this.initialAddress = response.data.addresses.find((item) => item.id > 0);
					this.address = this.initialAddress;
					if (response.data.addresses.length && this.timeToClosing() == true) {
						this.allowCheckout = true;
						if (this.deliveryPossible) {
							this.makeDefault = this.initialAddress.id;
						} else {
							this.makeDefault = -1;
							this.amount = this.total.toFixed(2);
						}
						this.getDeliveryCost();
					} else {
						this.allowCheckout = false;
						if (!response.data.addresses.length) {
							this.error += Vue.i18n.translate("minAddress") + "<br />";
						}
						if (this.timeToClosing() == false) {
							this.error += Vue.i18n.translate("minOrderTime") + "<br />";
						}
						this.amount = this.total.toFixed(2);
					}
					this.checkActivationStatus();
				});
			},
			setLoaded: function() {
				if (this.AllowPayPal) {
					this.loaded = true;
					var self = this;
					window.paypal
						.Buttons({
							style: {
								color: "gold",
							},
							createOrder: function(data, actions) {
								return actions.order.create({
									payer: {
										name: {
											given_name: self.address.firstName,
											surname: self.address.lastName,
										},
										address: {
											address_line_1: self.address.street,
											address_line_2: self.address.address,
											admin_area_2: self.address.city,
											postal_code: self.address.postCode,
											country_code: self.$root.shop_locale,
										},
										email_address: self.user.loginName,
									},
									purchase_units: [
										{
											amount: {
												value: self.amount,
											},
											shipping: {
												address: {
													address_line_1: self.address.street,
													address_line_2: self.address.address,
													admin_area_2: self.address.city,
													postal_code: self.address.postCode,
													country_code: self.$root.shop_locale,
												},
											},
											email_address: self.user.loginName,
										},
									],
								});
							},
							onApprove: function(data, actions) {
								self.error = "";
								self.paymentSelect = false;
								self.paymentSubmit = true;
								self.spinner = true;
								self.payType = "Bezahlen mit PayPal";
								self.paymentMethod = 3;
								return actions.order.capture().then(function(details) {
									self.sendOrder((self.transId = details.id));
								});
							},
						})
						.render(this.$refs.paypal);
				}
			},
			payCash() {
				this.error = "";
				this.nonce = "";
				this.paymentSelect = false;
				this.paymentSubmit = true;
				this.spinner = true;
				this.cancellation = false;
				this.payType = "Barzahlung";
				this.paymentMethod = 4;
				this.sendOrder((this.transId = "000"));
			},
			getWeekday() {
				var days = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
				var d = this.date;
				var dayName = days[d.getDay()];
				return dayName;
			},
			getTime() {
				var a = this.date;
				var year = a.getFullYear();
				var month = a.getMonth() + 1;
				var date = a.getDate();
				var hour = a.getHours();
				var min = a.getMinutes();
				var sec = a.getSeconds();
				if (min.toString().length == 1) {
					min = "0" + min;
				}
				if (sec.toString().length == 1) {
					sec = "0" + sec;
				}
				var time = date + "." + month + "." + year + " " + hour + ":" + min + ":" + sec;
				return time;
			},
			openHours() {
				var data = this.companyData.openHours;
				var arrOut = [];
				if (data) {
					for (var i = 0; i < data.length; i++) {
						if (data[i].weekDay === this.getWeekday()) {
							arrOut.push({
								fromHour: data[i].fromHour,
								toHour: data[i].toHour,
							});
						}
					}
				}
				var output = [];
				arrOut.forEach(function(item) {
					var today = new Date();
					var hour = today.getHours();
					var minutes = today.getMinutes();
					var timeOfDay = hour + minutes / 100;
					var from = item["fromHour"].replace(":", ".");
					var to = item["toHour"].replace(":", ".");
					if (timeOfDay >= from && timeOfDay <= to) {
						output.push({
							from: item["fromHour"],
							to: item["toHour"],
						});
					}
				});
				return output;
			},
			closingToday() {
				var data = this.companyData.openHours;
				var arrOut = [];
				if (data) {
					for (var i = 0; i < data.length; i++) {
						if (data[i].weekDay === this.getWeekday()) {
							arrOut.push({
								fromHour: data[i].fromHour,
								toHour: data[i].toHour,
							});
						}
					}
				}
				var output;
				arrOut.forEach(function(item) {
					var today = new Date();
					var hour = today.getHours();
					var minutes = today.getMinutes();
					var timeOfDay = hour + minutes / 100;
					var from = item["fromHour"].replace(":", ".");
					var to = item["toHour"].replace(":", ".");
					if (timeOfDay >= from && timeOfDay <= to) {
						output = item["toHour"];
					}
				});
				return output;
			},
			timeToClosing() {
				var bol;
				if (this.allowCheckout24) {
					bol = true;
				} else {
					if (this.onDuty) {
						var clTime = this.closingToday().split(":");
						var closingTime = new Date();
						closingTime.setHours(clTime[0], clTime[1]);
						var minutesToGo = (closingTime.getTime() - this.date.getTime()) / 1000 / 60;
						var mTG = minutesToGo.toFixed(0) - 15;

						if (mTG > 0) {
							bol = true;
						} else {
							bol = false;
						}
					} else {
						bol = false;
					}
				}
				return bol;
			},
			minToTime(min) {
				var now = new Date(Math.ceil(this.date.getTime() / 900000) * 900000);
				var newDate = new Date(now.getTime() + min * 60000);
				var h = newDate.getHours();
				var m = newDate.getMinutes();
				if (m == 0) {
					m = "00";
				}
				return h + ":" + m;
			},
			getDeliveryCost() {
				var cart_content = JSON.parse(this.shopCart);
				cart_content.payMethod = this.paymentMethod;
				cart_content.transactionReference = "Delivery Cost Calculation";
				cart_content.RoomNumber = this.input;
				cart_content.addressId = this.address.id;
				cart_content.comment = this.comment;
				cart_content.total = parseFloat(this.total);
				cart_content.tableNumber = this.companyData.tableNumber;
				if (this.makeDefault == -1) {
					cart_content.pickUp = true;
				} else {
					cart_content.pickUp = false;
				}

				DataService.getDeliveryCost(cart_content).then((response) => {
					this.DeliveryCost = response.data.amount;
					this.DeliveryTime = response.data.delieveryTimesList;
					this.DeliveryTimeDef = response.data.delieveryTimesList[0].text;

					if (this.initialAddress.id != -1) {
						this.amount = (this.total + this.DeliveryCost).toFixed(2);

						if (response.data.text !== "") {
							this.allowCheckout = false;
							this.error += Vue.i18n.translate(response.data.text) + "<br />";
						} else {
							this.allowCheckout = true;
							this.error = "";
						}
					} else {
						this.amount = this.total.toFixed(2);
						this.allowCheckout = true;
						this.error = "";
					}
				});
			},
			sendOrder(transId) {
				var cart_content = JSON.parse(this.shopCart);
				cart_content.payMethod = this.paymentMethod;
				cart_content.transactionReference = transId;
				cart_content.RoomNumber = this.input;
				cart_content.addressId = this.address.id;
				if (this.deliveryMinutes == 0) {
					cart_content.deliveryDate = this.DeliveryTime[0].time;
				} else {
					cart_content.deliveryDate = this.deliveryMinutes;
				}
				cart_content.comment = this.comment;
				cart_content.total = parseFloat(this.amount);
				cart_content.tableNumber = this.companyData.tableNumber;
				if (this.makeDefault == -1) {
					cart_content.pickUp = true;
				} else {
					cart_content.pickUp = false;
				}
				DataService.createOrder(cart_content);
				setTimeout(() => {
					this.removeAllFromCart();
					this.$router.push({
						name: "paymentSuccess",
						query: { paytype: this.paymentMethod },
					});
				}, 3000);
			},
			submitPayment() {
				this.cancellation = false;
				this.allowCheckout = true;
				this.sendOrder((this.transId = this.nonce));
			},
			goBack() {
				setTimeout(() => {
					this.$router.go(-1);
				}, 300);
			},
			reload() {
				setTimeout(() => {
					this.updateAddress();
				}, 300);
			},
			goHome() {
				setTimeout(() => {
					this.$router.push({ name: "order" });
				}, 300);
			},
			makeDefaultAddress(value) {
				this.makeDefault = value;
				if (this.allowCheckout) {
					this.getDeliveryCost();
				}
				if (value == -1) {
					this.address = this.user.addresses.find((item) => item.id > 0);
				} else {
					this.address = this.user.addresses.find((item) => item.id == value);
				}
				this.amount = (this.total + this.DeliveryCost).toFixed(2);
			},
			newAddress() {
				this.$router.push({ name: "newAddress" });
			},
			theme() {
				var hexcolor = this.companyData.shopBackgroundColorRgb.replace("#", "");
				var r = parseInt(hexcolor.substr(0, 2), 16);
				var g = parseInt(hexcolor.substr(2, 2), 16);
				var b = parseInt(hexcolor.substr(4, 2), 16);
				var yiq = (r * 299 + g * 587 + b * 114) / 1000;
				return yiq >= 128 ? "light" : "dark";
			},
		},
		mounted() {
			if (this.AllowPayPal) {
				const script = document.createElement("script");
				script.src =
					"https://www.paypal.com/sdk/js?client-id=" +
					this.companyData.payPalId +
					"&currency=" +
					this.companyData.storeCurrency +
					"&disable-funding=giropay,sepa,sofort";
				script.addEventListener("load", this.setLoaded);
				document.body.appendChild(script);
			}

			if (this.messageOfToday) {
				this.showModal();
			}
		},
	};
</script>

<style scoped>
	.hidePayment {
		visibility: hidden;
		height: 0 !important;
		margin: 0 !important;
		padding: 0 !important;
	}
	.cobtm {
		width: 272px;
		height: 48px;
		margin: auto;
	}
	.link {
		cursor: pointer;
		font-weight: bold;
		text-decoration: underline;
	}
	.pointer {
		cursor: pointer;
		vertical-align: middle;
	}

	.makeDefault {
		vertical-align: middle;
	}
</style>
