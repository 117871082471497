<template>
	<div>
		<h4>{{ $t("Anmeldung") }}</h4>
		<div class="row">
			<div class="col-lg-12">
				<div class="alert alert-danger" v-if="error">
					{{ $t(error) }}
				</div>
				<b-form @submit.prevent="login">
					<div class="panel p-2 mb-4">
						<h5>{{ $t("Email") }}:</h5>
						<b-form-input v-model="email" type="email" name="email" autocomplete="current-email" value></b-form-input>
						<h5>{{ $t("Passwort") }}:</h5>
						<b-form-input v-model="password" type="password" name="password" autocomplete="current-password" value></b-form-input>
						<button class="btn btn-primary mx-0 my-3" type="submit" name="button">
							{{ $t("Login") }}
						</button>
					</div>
				</b-form>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import DataService from "@/services/DataService.js";
	export default {
		name: "Login",
		data() {
			return {
				email: "",
				password: "",
				error: null,
			};
		},
		computed: {
			...mapGetters(["itemsQuantity", "taxes", "total", "companyData"]),
		},
		methods: {
			login() {
				this.$store
					.dispatch("login", {
						LoginName: this.email,
						Password: this.password,
					})
					.then(() => {
						if (this.total == 0) {
							this.$router.push({ name: "order" });
						}
					})
					.catch(() => {
						this.error = "Anmeldung fehlgeschlagen!";
					});
			},
			home() {
				this.$router.push({ name: "order" });
			},
		},
		beforeMount() {
			if (this.companyData.length != 0) {
				DataService.setStore(this.companyData.apiKey);
			}
		},
	};
</script>

<style scoped>
	h5 {
		margin-top: 5px;
	}
</style>
