<template>
	<div>
		<BaseIcon name="shopping-cart" width="26" height="26" class="my-1 mr-2" />
		<h2>{{ $t("Bezahlen") }}</h2>
		<div class="row">
			<div class="col-lg-12 px-1 py-1">
				<checkOut />
			</div>
		</div>
	</div>
</template>

<script>
	import checkOut from "@/components/shopping-cart/checkOut";

	export default {
		name: "CartPage",
		components: {
			checkOut,
		},
	};
</script>
